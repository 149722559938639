<template>
    <div class="block button-block">
        <h2>Vídeo</h2>
        <div class="wrapper">
            <div>
                <div>
                    <label for="link">Link do Vídeo no YouTube</label>
                    <input type="text" id="link" v-model="link" placeholder="Link do vídeo">
                </div>
            </div>
            <div class="preview">
                <div>
                    <p>Preview:</p>
                    <div class="embed-container">
                        <iframe :src="'https://www.youtube.com/embed/' + videoId" frameborder="0" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </div>
        <button class="danger" v-on:click="deleteBlock">
            <font-awesome-icon icon="trash"/>
        </button>
    </div>
</template>

<script>
export default {
    name: "VideoBlock",
    data() {
        return {
            link: 'https://www.youtube.com/watch?v=DRxawmG5MfM'
        }
    },
    props: {
        blockData: Object
    },
    mounted() {
        if (!this.blockData.data.content) {
            this.link = this.blockData.data.link
        }
    },
    methods: {
        deleteBlock() {
            this.$emit('delete', this.$props.blockData.id)
        },
        getData() {
            return {
                type: 'video',
                data: {
                    link: this.link
                }
            };
        },
    },
    computed: {
        videoId: function () {
            // eslint-disable-next-line no-useless-escape
            let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
            let match = this.link.match(regExp);
            return (match&&match[7].length==11)? match[7] : false;
        }
    }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/global";
@import "../../assets/scss/buttons";
@import "../../assets/scss/blocks";

.button-block {
    text-align: center;

    .wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
    }

    label {
        display: block;
        text-align: left;

        &:nth-of-type(1) {
            margin-top: 10px;
        }
    }

    input {
        padding: 10px;
        width: 100%;
        border: 1px solid $primary;
        border-radius: 10px;
        font-size: 1rem;
    }

    p {
        margin: 0 0 5px;
    }

    .btn {
        background-color: $primary;
        color: #fff;
        text-decoration: none;
        padding: 10px;
        transition: all 0.3s;
        display: inline-block;
        border-radius: 10px;

        &:hover {
            background-color: $alt;
        }
    }

    .preview {
        align-self: center;
    }

    .embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; }
    .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
}
</style>
