<template>
    <div class="block button-block">
        <h2>Arquivo</h2>
        <div class="wrapper">
            <div>
                <div>
                    <label for="title">Título</label>
                    <input type="text" id="title" v-model="file.title">
                </div>
                <div>
                    <label for="description">Descrição</label>
                    <textarea id="description" v-model="file.description"></textarea>
                </div>
                <div>
                    <label for="file">Arquivo para download</label>
                    <input type="file" id="file" ref="file" v-on:change="fileSelected">
                </div>
                <div>
                    <label for="image">Imagem</label>
                    <input type="file" id="image" ref="image" accept="image/*" v-on:change="imageSelected">
                </div>
            </div>
            <div class="preview">
                <div>
                    <p>Preview:</p>
                    <a :href="file.file.location" target="_blank">
                        <img :src="file.image.location" :alt="file.title" v-if="!loading">
                        <img src="../../assets/Rolling-1s-200px.gif" v-if="loading">
                        <br>
                        <small><b>{{ file.title }}</b></small>
                        <br>
                        <small>{{ file.description }}</small>
                    </a>
                </div>
            </div>
        </div>
        <button class="danger" v-on:click="deleteBlock">
            <font-awesome-icon icon="trash"/>
        </button>
    </div>
</template>

<script>
const axios = require('axios').default;

export default {
    name: "FileBlock",
    data() {
        return {
            loading: false,
            file: {
                title: 'Arquivo de Exemplo',
                description: 'Este é um arquivo de exemplo',
                image: {
                    key: '800x400',
                    location: 'http://placehold.it/800x400'
                },
                file: {
                    key: '',
                    location: '#'
                }
            }
        }
    },
    props: {
        blockData: Object
    },
    mounted() {
        if (!this.blockData.data.content) {
            this.file = this.blockData.data
        }
    },
    methods: {
        deleteBlock() {
            this.$emit('delete', this.$props.blockData.id)
        },
        getData() {
            return {
                type: 'file',
                data: this.file
            };
        },
        async fileSelected() {
            let self = this;
            let file = this.$refs.file.files[0];
            self.loading = true;
            self.file.file.location = '#';

            if (file) {
                const formData = new FormData();
                formData.append('file', file);

                axios.post(this.$apiUrl + 'files/upload', formData)
                    .then(response => {
                        self.file.file.key = response.data.file.key;
                        self.file.file.location = this.$mediaBaseUrl + response.data.file.key;
                        self.loading = false;
                    })
                    .catch(error => {
                        console.log(error);
                        self.loading = false;
                    });
            }
        },
        async imageSelected() {
            let self = this;
            let file = this.$refs.image.files[0];
            self.loading = true;
            self.file.image.location = '/Rolling-1s-200px.gif';

            if (file) {
                const formData = new FormData();
                formData.append('file', file);

                axios.post(this.$apiUrl + 'files/upload', formData)
                    .then(response => {
                        self.file.image.key = response.data.file.key;
                        self.file.image.location = this.$mediaBaseUrl + response.data.file.key;
                        self.loading = false;
                    })
                    .catch(error => {
                        console.log(error);
                        self.loading = false;
                    });
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/global";
@import "../../assets/scss/buttons";
@import "../../assets/scss/blocks";

.button-block {
    text-align: center;

    .wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
    }

    label {
        display: block;
        text-align: left;

        &:nth-of-type(1) {
            margin-top: 10px;
        }
    }

    input, textarea {
        padding: 10px;
        width: 100%;
        border: 1px solid $primary;
        border-radius: 10px;
        font-size: 1rem;
    }

    p {
        margin: 0 0 5px;
    }

    img {
        max-height: 200px;
        max-width: 100%;
        width: auto;
        margin: auto;
    }

    .btn {
        background-color: $primary;
        color: #fff;
        text-decoration: none;
        padding: 10px;
        transition: all 0.3s;
        display: inline-block;
        border-radius: 10px;

        &:hover {
            background-color: $alt;
        }
    }

    a {
        text-decoration: none;
        color: inherit;
    }

    .preview {
        align-self: center;
    }
}
</style>
