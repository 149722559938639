<template>
    <div class="block gallery-block">
        <h2>Galeria de Fotos</h2>
        <div class="wrapper">
            <div>
                <div>
                    <label for="image">Fotos</label>
                    <input type="file" id="image" accept="image/*" multiple v-on:change="filesSelected" ref="files">
                </div>
            </div>
            <br>
            <div class="preview">
                <div>
                    <p>Fotos:</p>
                    <img src="../../assets/Rolling-1s-200px.gif" v-if="loading">
                    <div v-if="!loading" class="photos">
                        <img :src="file.location" v-for="file in files" :key="file.key">
                    </div>
                </div>
            </div>
        </div>
        <button class="danger" v-on:click="deleteBlock">
            <font-awesome-icon icon="trash"/>
        </button>
    </div>
</template>

<script>
    // eslint-disable-next-line no-unused-vars
const axios = require('axios').default;

export default {
    name: "GalleryBlock",
    data() {
        return {
            loading: false,
            files: []
        }
    },
    props: {
        blockData: Object
    },
    mounted() {
        if (!this.blockData.data.content) {
            this.files = this.blockData.data
        }
    },
    methods: {
        deleteBlock() {
            this.$emit('delete', this.$props.blockData.id)
        },
        getData() {
          return {
              type: 'gallery',
              data: this.files
          };
        },
        async filesSelected() {
            let self = this;
            let filesSelect = this.$refs.files.files;
            self.loading = true;

            Array.from(filesSelect).forEach(file => {
                if (file) {
                    const formData = new FormData();
                    formData.append('file', file);

                    axios.post(this.$apiUrl + 'files/upload', formData)
                        .then(response => {
                            self.files.push(
                                {
                                    key: response.data.file.key,
                                    location: this.$mediaBaseUrl + response.data.file.key
                                }
                            );
                            self.loading = false;
                        })
                        .catch(error => {
                            // eslint-disable-next-line no-console
                            console.log(error);
                            self.loading = false;
                        });
                }
            });
        }
    }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/global";
@import "../../assets/scss/buttons";
@import "../../assets/scss/blocks";

.gallery-block {
    text-align: center;

    label {
        display: block;
        text-align: left;

        &:nth-of-type(1) {
            margin-top: 10px;
        }
    }

    input {
        padding: 10px;
        width: 100%;
        border: 1px solid $primary;
        border-radius: 10px;
        font-size: 1rem;
    }

    p {
        margin: 0 0 5px;
    }

    img {
        max-height: 50px;
        max-width: 100px;
        width: auto;
        margin: auto;
    }

    .btn {
        background-color: $primary;
        color: #fff;
        text-decoration: none;
        padding: 10px;
        transition: all 0.3s;
        display: inline-block;
        border-radius: 10px;

        &:hover {
            background-color: $alt;
        }
    }

    .preview {
        align-self: center;
    }
}
</style>
