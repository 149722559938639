<template>
    <div class="block image-block">
        <h2>Imagem</h2>
        <div class="wrapper">
            <div>
                <div>
                    <label for="image">Imagem</label>
                    <input type="file" id="image" accept="image/*" v-on:change="fileSelected" ref="file">
                </div>
                <div>
                    <label for="label">Legenda da imagem</label>
                    <input type="text" id="label" v-model="file.label" placeholder="Legenda da imagem">
                </div>
            </div>
            <div class="preview">
                <div>
                    <p>Preview:</p>
                    <img :src="file.location" :alt="file.label" v-if="!loading">
                    <img src="../../assets/Rolling-1s-200px.gif" v-if="loading">
                    <br>
                    <small>{{ file.label }}</small>
                </div>
            </div>
        </div>
        <button class="danger" v-on:click="deleteBlock">
            <font-awesome-icon icon="trash"/>
        </button>
    </div>
</template>

<script>
const axios = require('axios').default;

export default {
    name: "ImageBlock",
    data() {
        return {
            loading: false,
            file: {
                label: 'Esta é uma imagem',
                key: '800x400',
                location: 'http://placehold.it/800x400'
            }
        }
    },
    props: {
        blockData: Object
    },
    mounted() {
        if (!this.blockData.data.content) {
            this.file = this.blockData.data
        }
    },
    methods: {
        deleteBlock() {
            this.$emit('delete', this.$props.blockData.id)
        },
        getData() {
          return {
              type: 'image',
              data: this.file
          };
        },
        async fileSelected() {
            let self = this;
            let file = this.$refs.file.files[0];
            self.loading = true;
            self.file.location = '/Rolling-1s-200px.gif';

            if (file) {
                const formData = new FormData();
                formData.append('file', file);

                // axios.post(this.$apiUrl + 'files/upload', formData)
                axios.post(this.$apiUrl + 'files/upload', formData)
                    .then(response => {
                        self.file.key = response.data.file.key;
                        self.file.location = this.$mediaBaseUrl + response.data.file.key;
                        self.loading = false;
                    })
                    .catch(error => {
                        // eslint-disable-next-line no-console
                        console.log(error);
                        self.loading = false;
                    });
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/global";
@import "../../assets/scss/buttons";
@import "../../assets/scss/blocks";

.image-block {
    text-align: center;

    .wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
    }

    label {
        display: block;
        text-align: left;

        &:nth-of-type(1) {
            margin-top: 10px;
        }
    }

    input {
        padding: 10px;
        width: 100%;
        border: 1px solid $primary;
        border-radius: 10px;
        font-size: 1rem;
    }

    p {
        margin: 0 0 5px;
    }

    img {
        max-height: 200px;
        max-width: 100%;
        width: auto;
        margin: auto;
    }

    .btn {
        background-color: $primary;
        color: #fff;
        text-decoration: none;
        padding: 10px;
        transition: all 0.3s;
        display: inline-block;
        border-radius: 10px;

        &:hover {
            background-color: $alt;
        }
    }

    .preview {
        align-self: center;
    }
}
</style>
