<template>
    <div class="block text-block">
        <h2>Texto</h2>
        <editor
                :ref="'editor_' + this._uid"
                autofocus
                :holder-id="'codex-editor-' + this._uid"
                save-button-id="save-button"
                :init-data="initData"
                @save="save"
                @ready="onReady"
                @change="onChange"
        />
        <button class="danger" v-on:click="deleteBlock">
            <font-awesome-icon icon="trash"/>
        </button>
    </div>
</template>

<script>
export default {
    name: "TextBlock",
    data() {
        return {
            initData: {
                blocks: [
                    {
                        type : 'paragraph',
                        data : {
                            text: 'Insira seu texto aqui'
                        }
                    }
                ]
            },
            content: {}
        }
    },
    props: {
        blockData: Object
    },
    mounted() {
        if (!this.blockData.data.content) {
            this.initData.blocks = this.blockData.data.blocks;
        }
    },
    methods: {
        save(response) {
            this.content = response;
        },
        onReady() {
            this.$refs['editor_' + this._uid].save();
        },
        onChange() {
            this.$refs['editor_' + this._uid].save();
        },
        deleteBlock() {
            this.$emit('delete', this.$props.blockData.id)
        },
        getData() {
            return {
                type: 'text',
                data: this.content
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/global";
@import "../../assets/scss/buttons";
@import "../../assets/scss/blocks";

.text-block {
    text-align: left;
    #vue-editor-js {
        background-color: #f9f9f9;
        border-radius: 10px;
        border: 1px solid $background;
    }
}
</style>
