<template>
    <div class="block button-block">
        <h2>Botão com Link</h2>
        <div class="wrapper">
            <div>
                <div>
                    <label for="text">Texto do botão</label>
                    <input type="text" id="text" v-model="button.text" placeholder="Texto do botão">
                </div>
                <div>
                    <label for="link">Link do botão</label>
                    <input type="text" id="link" v-model="button.link" placeholder="Link do botão">
                </div>
            </div>
            <div class="preview">
                <div>
                    <p>Preview:</p>
                    <a :href="button.link" target="_blank" class="btn">{{ button.text }}</a>
                </div>
            </div>
        </div>
        <button class="danger" v-on:click="deleteBlock">
            <font-awesome-icon icon="trash"/>
        </button>
    </div>
</template>

<script>
export default {
    name: "ButtonBlock",
    data() {
        return {
            button: {
                text: 'Texto do Botão',
                link: ''
            }
        }
    },
    props: {
        blockData: Object
    },
    mounted() {
        if (!this.blockData.data.content) {
            this.button = this.blockData.data
        }
    },
    methods: {
        deleteBlock() {
            this.$emit('delete', this.$props.blockData.id)
        },
        getData() {
            return {
                type: 'button',
                data: this.button
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/global";
@import "../../assets/scss/buttons";
@import "../../assets/scss/blocks";

.button-block {
    text-align: center;

    .wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
    }

    label {
        display: block;
        text-align: left;

        &:nth-of-type(1) {
            margin-top: 10px;
        }
    }

    input {
        padding: 10px;
        width: 100%;
        border: 1px solid $primary;
        border-radius: 10px;
        font-size: 1rem;
    }

    p {
        margin: 0 0 5px;
    }

    .btn {
        background-color: $primary;
        color: #fff;
        text-decoration: none;
        padding: 10px;
        transition: all 0.3s;
        display: inline-block;
        border-radius: 10px;

        &:hover {
            background-color: $alt;
        }
    }

    .preview {
        align-self: center;
    }
}
</style>
